import { Box, Container, Button, styled } from '@material-ui/core'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps, navigate } from 'gatsby'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import Navbar from '../../../components/navbar'
import MobileNavbar from '../../../components/navbar/mobile'
import PageHeader from '../../../components/page-header'
import SuccessRegisterImage from '../../../images/registration/registration-successful.jpg'

const StyledPrimaryButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        marginBottom: '1rem',
      },
    }
  }}
  background-color: #008174;
  font-weight: bold;
  padding: 12px;
  width: 300px;
  margin-right: 5px;
`

const StyledSecondaryButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        marginBottom: '1rem',
      },
    }
  }}
  font-weight: bold;
  color: #008174;
  border-color: #008174;
  border-width: 3px;
  padding: 10px;
  width: 300px;
`

const RefRegisterSuccessPage = (props: PageProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <Container
        sx={{
          marginTop: '60px',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${SuccessRegisterImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            height: '400px',
            width: '100%',
            borderRadius: '6px',
          }}
        />
        <Box
          sx={{
            marginTop: '50px',
          }}
        >
          <PageHeader title="Registration successful" />
        </Box>
        <Box
          sx={{
            color: '#3C3835',
            fontSize: '20px',
          }}
        >
          <div
            style={{
              lineHeight: '25px',
            }}
          >
            Congratulations, your registration has been successful!
          </div>
          <div
            style={{
              lineHeight: '25px',
              marginTop: '30px',
            }}
          >
            You will receive an email confirming this shortly. Good luck and
            enjoy the season!
          </div>
        </Box>
        <Box
          sx={{
            marginTop: '40px',
            textAlign: 'center',
          }}
        >
          <StyledPrimaryButton
            variant="contained"
            onClick={() => {
              navigate('/register')
            }}
          >
            Add another registration
          </StyledPrimaryButton>
          <StyledSecondaryButton
            variant="outlined"
            onClick={() => {
              navigate('/about')
            }}
          >
            Back home
          </StyledSecondaryButton>
        </Box>
      </Container>
    </>
  )
}

export default withAuthenticationRequired(RefRegisterSuccessPage)
